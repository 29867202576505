body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 20px;
    text-align: justify;
}
h1, h2 {
    color: #2d3436;
}
a {
    color: #3498db;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
ul {
    margin: 0;
    padding-left: 20px;
}
p, li {
    margin-bottom: 1em;
}