/* Global styling */
.App {
  text-align: center;
  background-color: #e6f0fa; /* Light pastel blue background */
  color: #333;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
  min-height: 80vh;
}

/* Header */
.App-header {
  background-color: #fadcd9; /* Soft pastel pink */
  padding: 40px;
  color: #2d3436;
  border-bottom: 2px solid #ffcccb;
}

.cta-button {
  background-color: #ffb3ba; /* Soft pastel pink for contrast */
  color: white;
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #ff99a0; /* Slightly darker pink */
}

/* Features and Subscriptions */
.features {
  padding: 40px 20px;
  background-color: #ffffff; /* Soft white background */
  margin: 20px auto;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0,0,0,0.1);
  z-index: 1;
}

.subscription {
  padding: 40px 20px;
  background-color: #ffffff; /* Soft pastel blue */
  margin: 20px auto;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0,0,0,0.1);
  z-index: 1;
}

h2 {
  color: #2d3436;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  font-size: 1.2em;
  margin: 10px 0;
}

/* Footer */
.App-footer {
  background-color: #fadcd9; /* Soft pastel pink */
  padding: 20px;
  color: #2d3436;
  bottom: 0px;
  position: relative;
  width: 100%;
  text-align: center;
}

/* Animated Elements */
.animated-stars, .animated-moons, .animated-clouds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 0; /* Ensure they are in the background */
}

/* CSS Shapes for stars, moons, and clouds */
.animated-stars {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: move-stars 20s linear infinite;
}

.star {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ffd700; /* Default star color */
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.star::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #ffdd99;
  position: absolute;
  top: -10px;
  left: -10px;
}

/* Crescent Moon */
.animated-moons {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: move-moons 30s linear infinite;
}

.moon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffe066;
  position: absolute;
}

.moon::after {
  content: '';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e6f0fa; /* Same as background */
  position: absolute;
  top: -5px;
  left: 15px;
}

/* Clouds */
.animated-clouds {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: move-clouds 60s linear infinite;
}

.cloud {
  width: 100px;
  height: 60px;
  background: #fff;
  border-radius: 50px;
  position: absolute;
}

.cloud::before, .cloud::after {
  content: '';
  position: absolute;
  background: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.cloud::before {
  top: -30px;
  left: 10px;
}

.cloud::after {
  top: -20px;
  left: 40px;
}

/* Animations */
@keyframes move-stars {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1000px, 1000px);
  }
}

@keyframes move-moons {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2000px, 2000px);
  }
}

@keyframes move-clouds {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-1000px, 1000px);
  }
}

/* Place stars, moons, and clouds */
.star:nth-child(1) {
  top: 20px;
  left: 10%;
}

.star:nth-child(2) {
  top: 50px;
  left: 30%;
}

.moon:nth-child(1) {
  top: 100px;
  left: 70%;
}

.cloud:nth-child(1) {
  top: 150px;
  left: 40%;
}

.cloud:nth-child(2) {
  top: 200px;
  left: 80%;
}
