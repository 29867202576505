body {
    font-family: 'Arial', sans-serif;
    background-color: #f0f8ff; /* Soft blue background */
    margin: 0;
    padding: 0;
  }
  
  .contact-container {
    width: 100%;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  textarea.form-input {
    height: 100px;
    resize: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50; /* Green button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .form-status {
    text-align: center;
    margin-top: 10px;
    color: #ff3333; /* Red for errors */
  }
  
  .recaptcha-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  